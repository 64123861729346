import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core'
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormBuilder, Validator, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GoogleAnalyticsService } from 'app/_services/google-analytics.service'
import { SharedService } from 'app/_services/shared.service'
import { environment } from 'environments/environment'
import { delay, distinctUntilChanged } from 'rxjs/operators'
import { InformationIconComponent } from '../information-icon/information-icon.component'
import { createAddressFormGroup } from 'app/_helpers/orderworkForm'
import { TippyDirective } from '../directives/tippy.directive'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { InputValidationDirective } from '../directives/input-validation.directive'
import { FormFloatingComponent } from '../form/form-floating/form-floating.component'
import { CommonModule } from '@angular/common'
import { I18nDirective } from '../directives/app-i18n.directive'
import { firstValueFrom } from 'rxjs'
import { I18nService, MessageGetter } from 'app/_services/i18n.service'
import { CountryCodePipe } from '../pipes/country-code.pipe'

@Component({
    selector: 'form-address',
    templateUrl: './form-address.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormAddressComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormAddressComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        FormFloatingComponent,
        FormsModule,
        InputValidationDirective,
        ReactiveFormsModule,
        FontAwesomeModule,
        TippyDirective,
        InformationIconComponent,
        I18nDirective,
        CountryCodePipe,
    ],
})
export class FormAddressComponent implements Validator {
    @ViewChild('distanceInformationIcon') informationIcon: InformationIconComponent
    @Output() modalShown = new EventEmitter()
    @Input() orderworkTemplate
    @Input() identifier
    @Input() hasRemovalInfo = false

    form
    message = {
        userDontKnow: false,
        notFound: false,
        abroad: false,
    }

    postalLoading = false

    /** Feedback message as to why country selection is invalid */
    get formCountryInvalidFeedback(): string {
        return (
            this.i18n('addressDetails_country_invalid_' + this.form.get('country').value.toUpperCase()) ??
            this.i18n('addressDetails_country_invalid_default')
        )
    }

    // Shortcut
    readonly i18n: MessageGetter = this.i18nService.getMessage.bind(this.i18nService)

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private sharedService: SharedService,
        private fb: UntypedFormBuilder,
        private i18nService: I18nService
    ) {}

    public writeValue(address) {
        // Create form
        this.initForm(address)

        this.checkDisabled()
        this.checkPostalExists()

        // Listen for values in form
        this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(this.doPropagateChange.bind(this))
    }

    public validate() {
        return this.form.invalid ? { address: true } : null
    }

    public registerOnChange(fn) {
        this.propagateChange = fn
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public registerOnTouched() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private propagateChange = (_: unknown) => {}

    private doPropagateChange() {
        const propagate = this.form.getRawValue()

        // Propagate form change
        this.propagateChange(propagate)
    }

    initForm(address) {
        this.form = createAddressFormGroup(this.fb, address)
    }

    checkPostalExists() {
        const { country, postal, number, city, street } = this.form.getRawValue()
        const hasPostalValues = country && postal && number
        const hasNoCityOrStreet = !city || !street
        if (hasPostalValues && hasNoCityOrStreet) this.addressFromPostal()
    }

    addressPostalClean() {
        const formPostal = this.form.get('postal')
        const formPostalClean = formPostal.value
            .trim()
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, '')
        if (formPostal.value === formPostalClean) return
        formPostal.patchValue(formPostalClean)
    }

    async addressFromPostal() {
        this.addressPostalClean()

        const addrPostal = this.form.get('postal')
        const addrCountry = this.form.get('country')
        const addrNumber = this.form.get('number')

        const addrCountryAbroad = addrCountry.value !== 'nl'
        const addrPostalNoValue = !addrPostal.value
        const addrPostalUserDontKnow = this.message.userDontKnow
        const addrNumberNoValue = !addrNumber.value
        const addrPostalIncorrectLength = (addrPostal.value || '').length < 4
        const addrPostalLoading = this.postalLoading

        const addrCheckDisabled =
            addrPostalLoading ||
            addrPostalUserDontKnow ||
            addrCountryAbroad ||
            addrPostalNoValue ||
            addrNumberNoValue ||
            addrPostalIncorrectLength

        if (addrCheckDisabled) return this.checkDisabled()

        this.postalLoading = true
        const res = await firstValueFrom(
            this.sharedService.addressFromPostal(this.form.value).pipe(delay(environment.production ? 0 : 250))
        ).catch((err) => ({ data: { error: err } }))

        this.postalLoading = false
        if (res.data.error) return this.postalNotFound()

        const data = res.data
        this.form.patchValue({
            street: data.street,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
        })
        this.setEnterPostalOnly()
    }

    checkDisabled() {
        const addrPostal = this.form.get('postal')
        const addrCountry = this.form.get('country')

        const addrCountryAbroad = addrCountry.value !== 'nl'
        const addrPostalInvalid = addrPostal.value && addrPostal.valid === false
        const addrPostalUserDontKnow = this.message.userDontKnow

        // If not in the Netherlands we cannot postcode
        if (addrCountryAbroad) return this.postalAbroad()
        if (addrPostalInvalid) return this.postalNotFound()
        if (addrPostalUserDontKnow) return
        this.setEnterPostalOnly()
    }

    // User has specified that it doesn't know their postal code
    postalUserDontKnow() {
        this.setEnterDetails('userDontKnow')
        this.googleAnalyticsService.gtmEmit('PostalUserDontKnow', this.identifier, this.i18nService.languageId)
    }

    // We could not find the postal entered by the user
    postalNotFound() {
        this.setEnterDetails('notFound')
        this.googleAnalyticsService.gtmEmit('PostalNotFound', this.identifier, this.i18nService.languageId)
    }

    // We only do Dutch postal
    postalAbroad() {
        this.setEnterDetails('abroad')
        this.googleAnalyticsService.gtmEmit('PostalAbroad', this.identifier, this.i18nService.languageId)
    }

    private setEnterPostalOnly() {
        Object.keys(this.message).forEach((k) => (this.message[k] = false))
        this.form.get('city').disable()
        this.form.get('street').disable()
    }

    private setEnterDetails(error) {
        // this.form.get('postal').setErrors({ [error]: true });
        Object.keys(this.message).forEach((k) => (this.message[k] = false))
        this.message[error] = true
        this.form.get('city').enable()
        this.form.get('street').enable()
    }

    setMoveType() {
        if (this.form.value.liftExternal) return this.form.get('moveType').patchValue('liftexternal')
        if (this.form.value.liftInternal) return this.form.get('moveType').patchValue('liftinternal')
    }

    optionsDistanceToDoor = [
        { value: 0, label: this.i18n('addressDetails_distance_zero') },
        { value: 10, label: this.i18n('addressDetails_distance_ten') },
        { value: 20, label: this.i18n('addressDetails_distance_twenty') },
        { value: 30, label: this.i18n('addressDetails_distance_thirty') },
        { value: 40, label: this.i18n('addressDetails_distance_fourty') },
        { value: 50, label: this.i18n('addressDetails_distance_fifty') },
        { value: 75, label: this.i18n('addressDetails_distance_seventyFive') },
        { value: 100, label: this.i18n('addressDetails_distance_oneHundred') },
        { value: 150, label: this.i18n('addressDetails_distance_hundredFifty') },
        { value: 200, label: this.i18n('addressDetails_distance_twoHundred') },
    ]
    optionsMoveMethod = [
        { value: 'stairs', label: this.i18n('addressDetails_moveMethod_stairs') },
        { value: 'hoistup', label: this.i18n('addressDetails_moveMethod_hoistup') },
        { value: 'liftinternal', label: this.i18n('addressDetails_moveMethod_liftInternal') },
    ]
    optionsFloor = [
        { value: 0, label: this.i18n('addressDetails_floor_ground') },
        { value: -1, label: this.i18n('addressDetails_floor_sous') },
        { value: 1, label: this.i18n('addressDetails_floor_first') },
        { value: 2, label: this.i18n('addressDetails_floor_second') },
        { value: 3, label: this.i18n('addressDetails_floor_third') },
        { value: 4, label: this.i18n('addressDetails_floor_fourth') },
        { value: 5, label: this.i18n('addressDetails_floor_fifth') },
        { value: 6, label: this.i18n('addressDetails_floor_sixth') },
        { value: 7, label: this.i18n('addressDetails_floor_seventh') },
        { value: 8, label: this.i18n('addressDetails_floor_eigth') },
        { value: 9, label: this.i18n('addressDetails_floor_ninth') },
        { value: 10, label: this.i18n('addressDetails_floor_tenPlus') },
    ]
}
