import { Pipe, PipeTransform } from '@angular/core'
import { I18nService } from 'app/_services/i18n.service'

@Pipe({
    name: 'countryCode',
    standalone: true,
})
export class CountryCodePipe implements PipeTransform {
    constructor(private i18nService: I18nService) {}

    /**
     * Transforms a country code into a more readable format.
     * For example, 'GB' becomes 'United Kingdom'.
     * @param countryCode - The country code to transform.
     * @returns A formatted string representing the country name.
     */
    transform(countryCode: string): string {
        if (!countryCode) return ''

        try {
            // Create Intl.DisplayNames instance for region display
            const regionNames = new Intl.DisplayNames(this.i18nService.languageId || 'en-US', { type: 'region' })

            // Convert to uppercase if it's a 2-letter code
            const normalizedCode = countryCode.length === 2 ? countryCode.toUpperCase() : countryCode

            // Get the region display name
            return regionNames.of(normalizedCode)
        } catch (error) {
            // Fallback in case of invalid code or unsupported browser
            return countryCode
        }
    }
}
