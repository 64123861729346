.input-group-addon {
    padding: 3px 9px;
}

.square {
}

.popover {
    max-width: 294px;
    min-width: 270px;

    .popover-body {
        text-decoration: none !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
        margin-bottom: -10px;
        user-select: none;
    }
}

// New with 2020 experience
.day-holder {
    padding-left: 1.5%;
    padding-right: 1.5%;
    width: 100%;
    padding-bottom: 10px;

    .day-holder-line {
        text-align: center;

        .day.day-of-week {
            font-size: 0.8em;
            height: 32px;
        }

        .day {
            width: 14.28%;
            float: left;
            cursor: pointer;

            .number {
                position: relative;
                width: 42px;
                height: 42px;
                line-height: 42px;
                font-size: 1.15em;
                font-weight: 500;
                margin: auto;
                z-index: 0;

                border-radius: 100%;

                .select-indicator {
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -100;
                    border-radius: 100%;
                }
            }

            &.date-range-highlight {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: inherit;
                    height: 28px;
                    margin-top: 8px;
                    background-color: #f3f2f5;
                }

                &.date-range-highlight-start:before {
                    border-radius: 25px 0px 0px 25px;
                }

                &.date-range-highlight-end:before {
                    border-radius: 0px 25px 25px 0px;
                }
            }

            @media (hover: hover) {
                &:hover {
                    .select-indicator {
                        background-color: #f3f2f5;
                    }
                }
            }

            // Selected
            &.month-current.selected {
                .number {
                    color: white;
                    font-weight: 600;
                }

                // Default select indicator
                .number {
                    background-color: #00d775;
                }
            }

            // Discount
            &.date-discount-low {
                .discount {
                    // color: #d1435b;
                    // color: #000;
                    color: #fff;
                }
                &.selected .number {
                    // background-color: #d1435b;
                    background-color: #000;
                }
            }

            &.date-discount-medium {
                .discount {
                    color: #ff9400;
                }
                &.selected .number {
                    background-color: #ff9400;
                }
            }

            &.date-discount-high {
                .discount {
                    color: #00a698;
                }
                &.selected .number {
                    background-color: #00a698;
                }
            }

            .discount {
                margin: auto;
                margin-top: -4px;
                width: 42px;
                height: 17px;
                font-size: 0.9em;
            }
        }

        .day.month-next,
        .day.month-previous {
            color: #b2aebd;
            .discount,
            .select-indicator {
                visibility: hidden;
            }
        }

        .day.date-disabled,
        .day.date-range-before,
        .day.date-range-after {
            color: #b2aebd;
            cursor: not-allowed;
            .discount {
                visibility: hidden;
            }
        }
    }
}
// New with 2020 experience
table tr td {
    .discount {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        font-size: 0.7em;
    }
}
