form-group-horizontal {
    /* Make readonly elements non-clickable */
    [readonly] {
        pointer-events: none;
    }

    /* Unused padding */
    .form-group-container > label > label {
        margin-bottom: 0;
    }

    /* Hide empty help-text */
    .form-group-container.row .form-text.text-muted:empty {
        display: none;
    }
}

/* Last element should not have margin */
fieldset form-group-horizontal:last-child .form-group-container,
.card form-group-interview-question:last-child form-group-horizontal .form-group-container,
.card form-group-horizontal:last-child .form-group-container {
    margin-bottom: 0;
}
