import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'

@Directive({
    selector: '[input-validation]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.required]': 'isRequired ? true : null',
        '[class.is-valid]': 'formControl.touched && formControl.valid',
        '[class.is-invalid]': 'formControl.touched && formControl.invalid',
    },
    standalone: true,
})
export class InputValidationDirective implements OnInit, OnChanges {
    @Input({ required: true }) formControl: FormControl
    @Input() invalidFeedback: string

    // Required is used to trigger BS Validation
    // https://medium.com/the-tech-masters/bootstrap-form-validation-in-angular-1b441d689145
    get isRequired() {
        if (!this.formControl.validator) return false
        const validator = this.formControl.validator(this.formControl as AbstractControl)
        return validator && validator.required
    }

    constructor(private el: ElementRef<HTMLSelectElement>) {}

    ngOnInit(): void {
        this.updateValidity()
        // Listen to validity changes
        this.formControl.statusChanges.subscribe(() => this.updateValidity())
    }

    /** Update the validity of the input so native HTML input validation works. */
    private updateValidity() {
        if (!this.formControl.validator) return

        const element = this.el.nativeElement
        if (this.formControl.invalid) element.setCustomValidity(this.invalidFeedback || 'Invalid input')
        else element.setCustomValidity('')

        if (this.invalidFeedback) element.reportValidity()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.invalidFeedback?.currentValue) this.updateValidity()
    }
}
