import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { debounceTime, tap } from 'rxjs/operators'
import { FormAddressComponent } from '../form-address/form-address.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgIf, NgFor } from '@angular/common'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { I18nDirective } from '../directives/app-i18n.directive'
import { I18nService, MessageGetter } from 'app/_services/i18n.service'

@Component({
    selector: 'form-addresses',
    templateUrl: './form-addresses.component.html',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FontAwesomeModule, FormAddressComponent, BsDropdownModule, I18nDirective],
})
export class FormAddressesComponent implements OnInit {
    @Output() modalShown = new EventEmitter()
    /** orderworkForm */
    @Input({ required: true }) form
    @Input() orderworkTemplate
    @Input() hasRemovalInfo = true

    get formEstimateAddresses() {
        return this.form?.get('estimate.addresses')
    }
    get formInvoiceAddress() {
        return this.form?.get('invoiceAddress')
    }
    get formInvoice() {
        return this.form?.get('invoice')
    }
    get identifier() {
        return this.form?.value.identifier
    }

    readonly i18n: MessageGetter = this.i18nService.getMessage.bind(this.i18nService)

    addressesMax = 10
    addressesSubscriptions = []

    constructor(private i18nService: I18nService) {}

    ngOnInit() {
        // Listen to changes to invoiceadress
        this.formEstimateAddresses.valueChanges.pipe(tap((data) => this.changeInvoiceAddress(data))).subscribe()

        this.formEstimateAddresses.valueChanges.pipe(debounceTime(1000)).subscribe((addresses) => {
            this.checkMoveType(addresses)
        })

        // Trigger
        this.formEstimateAddresses.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true,
        })
    }

    onModalShown() {
        this.modalShown.emit()
    }

    checkMoveType(addresses) {
        const address = addresses.find((addr) => addr.moveType === 'liftexternal')
        if (address) this.form.get('estimate.info.movingLift').patchValue(true)
    }

    getAddressString(index, first, last) {
        if (!last && !first) return `${this.i18n('addressDetails_label_other')} ${String.fromCharCode(index + 65)}`
        if (first) return `${this.i18n('addressDetails_invoiceAddress_option_startAddress')} ${String.fromCharCode(index + 65)}`
        if (!first && last) return `${this.i18n('addressDetails_invoiceAddress_option_endAddress')} ${String.fromCharCode(index + 65)}`
    }

    removeAddress(index) {
        this.formEstimateAddresses.removeAt(index)
    }

    addAddress(index) {
        this.formEstimateAddresses.insert(
            index,
            new FormControl({
                country: 'nl',
                city: '',
                postal: '',
                street: '',
                number: '',
                addition: '',
                description: '',
                floor: 0,
                locationType: 'other',
                moveType: 'stairs',
                liftInternal: false,
                liftExternal: false,
                distanceToDoor: null,

                latitude: null,
                longitude: null,
            })
        )
    }

    /**
     * Animates the permutation of two elements in a parent element.
     *
     * @param idx0 - The index of the first element to be animated.
     * @param idx1 - The index of the second element to be animated.
     * @param parentElem - The parent element containing the elements to be animated.
     * @param transition - The duration of the animation in milliseconds. Default is 600ms.
     * @returns A Promise that resolves after the animation is complete.
     */
    async animatePermutation(idx0, idx1, parentElem: HTMLElement, transition = 600) {
        // Get x, y position of children
        const children = Array.from(parentElem.children)
        const child0 = children[idx0] as HTMLElement
        const child1 = children[idx1] as HTMLElement
        const pos0 = child0.getBoundingClientRect()
        const pos1 = child1.getBoundingClientRect()
        const diffX = pos1.x - pos0.x
        const diffY = pos1.y - pos0.y

        // Animate moving the first element to the position of the second element
        child0.animate([{ transform: `translate(${diffX}px, ${diffY}px)` }, { transform: 'none' }], {
            duration: transition,
            fill: 'none',
            easing: 'ease',
        })

        // Animate moving the second element to the position of the first element
        child1.animate([{ transform: `translate(${-diffX}px, ${-diffY}px)` }, { transform: 'none' }], {
            duration: transition,
            fill: 'none',
            easing: 'ease',
        })
    }

    /**
     * Permutes the addresses at the specified indices in the form.
     * If a root element is provided, animates the permutation with the specified transition duration.
     *
     * @param idx0 - The index of the first address to permute.
     * @param idx1 - The index of the second address to permute.
     * @param parentElem - The parent element containing the elements to be animated.
     * @param transition - The transition duration in milliseconds. Defaults to 600.
     */
    async permutateAddresses(idx0, idx1, rootElem: HTMLElement = null, transition = 600) {
        if (rootElem && transition) await this.animatePermutation(idx0, idx1, rootElem, transition)

        // Swap the addresses in the form
        const form = this.formEstimateAddresses
        const addresses = form.value
        const temp = addresses[idx0]
        addresses[idx0] = addresses[idx1]
        addresses[idx1] = temp
        form.patchValue(addresses)
    }

    changeInvoiceAddress(data) {
        const newAddresses = data ? data : this.form.value.estimate.addresses
        // In this case we should clear
        if (this.formInvoice.value === 'other') return

        if (!newAddresses[this.formInvoice.value]) this.formInvoice.patchValue(newAddresses.length - 1)

        const addr = newAddresses[this.formInvoice.value]
        this.formInvoiceAddress.patchValue(addr)
    }
}
