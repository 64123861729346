<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <h4 class="card-title" i18n="@@moveDetails_moveDate_label_certain" app-i18n="moveDetails_moveDate_label_certain">
                Voorkeursdatum en tijd verhuizing
            </h4>

            <form-floating class="mt-2">
                <label i18n="@@moveDetails_startCertainty_label" app-i18n="moveDetails_startCertainty_label">
                    Is je verhuisdatum flexibel? *
                </label>
                <select class="form-control" [formControl]="formEstimateStartCertainty" required>
                    <option value="" disabled i18n="@@moveDetails_startCertainty_choose" app-i18n="moveDetails_startCertainty_choose">
                        Kies een optie
                    </option>
                    <option value="certain" i18n="@@moveDetails_startCertainty_certain" app-i18n="moveDetails_startCertainty_certain">
                        Nee, ik heb al een datum
                    </option>
                    <option value="estimate" i18n="@@moveDetails_startCertainty_estimate" app-i18n="moveDetails_startCertainty_estimate">
                        Ja, ik ben flexibel
                    </option>
                    <option value="unknown" i18n="@@moveDetails_startCertainty_unknown" app-i18n="moveDetails_startCertainty_unknown">
                        Ik weet de datum nog niet
                    </option>
                </select>
            </form-floating>

            <form-floating class="d-none d-sm-block" *ngIf="formEstimate.get('startCertainty').value === 'estimate'">
                <label i18n="@@moveDetails_startCertaintyDates_label" app-i18n="moveDetails_startCertaintyDates_label">
                    Welke periode?
                </label>
                <input
                    type="text"
                    placeholder="DD-MM-YYYY - DD-MM-YYYY"
                    i18n-placeholder="@@moveDetails_startCertaintyDates_place"
                    [attr.placeholder]="i18n('moveDetails_startCertaintyDates_place')"
                    class="form-control"
                    bsDaterangepicker
                    [formControl]="formEstimate.get('startCertaintyDates')"
                    [bsConfig]="bsDatepickerConfig"
                    input-validation
                />
                <span help-text i18n="@@moveDetails_startCertaintyDates_helpText" app-i18n="moveDetails_startCertaintyDates_helpText">
                    Als je bepaalde dagen niet kunt in de periode is dat geen probleem. Indien je voorkeursdatum niet beschikbaar is zal
                    iemand contact met je opnemen om de mogelijkheden te bespreken.
                </span>
            </form-floating>

            <div class="d-block d-sm-none mb-3" *ngIf="formEstimate.get('startCertainty').value === 'estimate'">
                <label class="form-label" i18n="@@moveDetails_startCertaintyDates_label" app-i18n="moveDetails_startCertaintyDates_label">
                    Welke periode?
                </label>
                <div>
                    <bs-daterangepicker-inline
                        [bsValue]="formEstimate.get('startCertaintyDates').value"
                        [bsConfig]="bsDatepickerConfig"
                        (bsValueChange)="changeStartCertaintyDates($event)"
                    />
                </div>
                <small
                    class="form-text text-muted"
                    i18n="@@moveDetails_startCertaintyDates_helpText"
                    app-i18n="moveDetails_startCertaintyDates_helpText"
                >
                    Als je bepaalde dagen niet kunt in de periode is dat geen probleem. Indien je voorkeursdatum niet beschikbaar is zal
                    iemand contact met je opnemen om de mogelijkheden te bespreken.
                </small>
            </div>

            <ng-container
                *ngIf="
                    formEstimateStartCertainty.valid &&
                    (formEstimate.get('startCertainty').value !== 'estimate' || formEstimate.get('startCertaintyDates').valid) &&
                    formEstimate.get('startCertainty').value !== 'unknown'
                "
            >
                <label i18n="@@moveDetails_date_label" app-i18n="moveDetails_date_label">Voorkeursdatum</label>
                <date-picker
                    *ngIf="dateConfig || !this.shouldShowDiscount"
                    [formControl]="formEstimateStart"
                    [dateRangeHighlight]="formEstimate.get('startCertaintyDates').value"
                    [language]="languageId"
                    [type]="'inline'"
                    [time]="true"
                    i18n-timeDescription="@@moveDetails_moveTime_label"
                    [timeDescription]="i18n('moveDetails_moveTime_label')"
                    [timePrecision]="30"
                    [timeRangeFrom]="9"
                    [timeRangeTo]="20"
                    [dateConfig]="dateConfig"
                    [dateInit]="dateInit"
                    [dateFrom]="dateFrom"
                    [dateTo]="dateTo"
                    [dateWarning]="[
                        {
                            title: i18n('date_errors_past_title'),
                            message: i18n('date_errors_past_message'),
                            type: 'in-past',
                            style: 'danger'
                        },
                        {
                            title: i18n('date_errors_emergency_title'),
                            message: i18n('date_errors_emergency_message'),
                            type: 'range',
                            params: [0, 3],
                            style: 'warning'
                        },
                        {
                            title: i18n('date_errors_farAway_title'),
                            message: i18n('date_errors_farAway_message'),
                            type: 'range',
                            params: [365, 999999],
                            style: 'warning'
                        }
                    ]"
                ></date-picker>

                <div class="row">
                    <div class="offset-md-4 col-md-8">
                        <div
                            class="alert alert-success"
                            *ngIf="getCurrentDateConfig().discount"
                            i18n="@@moveDetails_date_discountPre"
                            app-i18n="moveDetails_date_discountPre"
                            [interpolationValues]="[getCurrentDateConfig().discount | percent]"
                        >
                            Op de geselecteerde dag krijg je {{ getCurrentDateConfig().discount | percent }} korting!
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
